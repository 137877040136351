import * as React from "react";

const css = `
  .cls-1{fill:none;}
  .cls-2{clip-path: url(#clip-path);}
  .cls-3{clip-path: url(#clip-path-2);}
  .cls-4{fill:#e88a0e;}
  .cls-5{clip-path: url(#clip-path-3);}
  .cls-6{clip-path: url(#clip-path-4);}
  .cls-7{clip-path: url(#clip-path-5);}
  .cls-8{clip-path: url(#clip-path-6);}
  .cls-9{clip-path: url(#clip-path-7);}
  .cls-10{clip-path: url(#clip-path-8);}
  .cls-11{clip-path: url(#clip-path-9);}
  .cls-12{clip-path: url(#clip-path-10);}
  .cls-13{clip-path: url(#clip-path-11);}
  .cls-14{clip-path: url(#clip-path-12);}
  .cls-15{clip-path: url(#clip-path-13);}
`

function SolicitarAtendimentoIcon(props) {
    return (
        <svg
            width={25}
            height={25}
            viewBox="0 0 27.61 33.05"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs>
                <style>{css}</style>
                <clipPath id="clip-path" transform="translate(-5 -1)"><rect className="cls-1" x="12.1" y="18.54" width="8.36" height="22.55" transform="matrix(0, -1, 1, 0, -13.62, 45.97)" /></clipPath>
                <clipPath id="clip-path-2" transform="translate(-5 -1)"><path className="cls-1" d="M26,34,6.52,34a1.54,1.54,0,0,1-1.07-.44A1.43,1.43,0,0,1,5,32.47L5,26.8a1.44,1.44,0,0,1,.45-1.05,1.5,1.5,0,0,1,1.07-.42l19.5.08a1.5,1.5,0,0,1,1.07.44,1.47,1.47,0,0,1,.44,1l0,5.68a1.48,1.48,0,0,1-.44,1A1.57,1.57,0,0,1,26,34Z" /></clipPath>
                <clipPath id="clip-path-3" transform="translate(-5 -1)"><rect className="cls-1" x="8.17" y="22.77" width="19.38" height="6.37" /></clipPath>
                <clipPath id="clip-path-4" transform="translate(-5 -1)"><path className="cls-1" d="M25,22.77c1.41,0,2.56,1.42,2.56,3.18S26.38,29.13,25,29.13H10.72c-1.41,0-2.55-1.42-2.55-3.18s1.14-3.18,2.55-3.18Z" /></clipPath>
                <clipPath id="clip-path-5" transform="translate(-5 -1)"><rect className="cls-1" x="5" y="22.77" width="9.43" height="8.77" /></clipPath>
                <clipPath id="clip-path-6" transform="translate(-5 -1)"><path className="cls-1" d="M9.71,22.77A4.55,4.55,0,0,0,5,27.15a4.56,4.56,0,0,0,4.71,4.39,4.55,4.55,0,0,0,4.7-4.39A4.55,4.55,0,0,0,9.71,22.77Z" /></clipPath>
                <clipPath id="clip-path-7" transform="translate(-5 -1)"><rect className="cls-1" x="25.43" y="1" width="6.57" height="17.2" /></clipPath>
                <clipPath id="clip-path-8" transform="translate(-5 -1)"><path className="cls-1" d="M25.43,3.51c0-1.39,1.47-2.5,3.29-2.5S32,2.12,32,3.51V15.7c0,1.38-1.47,2.5-3.28,2.5s-3.29-1.12-3.29-2.5Z" /></clipPath>
                <clipPath id="clip-path-9" transform="translate(-5 -1)"><rect className="cls-1" x="21.23" y="13.18" width="10.69" height="16.1" /></clipPath>
                <clipPath id="clip-path-10" transform="translate(-5 -1)"><polygon className="cls-1" points="20.51 27.67 26.48 12.35 32.62 14.64 26.64 29.96 20.51 27.67" /></clipPath>
                <clipPath id="clip-path-11" transform="translate(-5 -1)"><path className="cls-1" d="M25.56,14.71c.51-1.29,2.29-1.83,4-1.2S32.2,15.7,31.7,17L27.55,27.62c-.5,1.29-2.28,1.83-4,1.19s-2.66-2.19-2.16-3.48Z" /></clipPath>
                <clipPath id="clip-path-12" transform="translate(-5 -1)"><rect className="cls-1" x="8" y="6" width="15.43" height="15.28" /></clipPath>
                <clipPath id="clip-path-13" transform="translate(-5 -1)"><ellipse className="cls-1" cx="15.71" cy="13.64" rx="7.71" ry="7.64" /></clipPath>
            </defs>
            <g className="cls-2"><g className="cls-3"><path className="cls-4" d="M27.54,34.05,5,34l0-8.63,22.52.1Z" transform="translate(-5 -1)" /></g></g>
            <g className="cls-5"><g className="cls-6"><path className="cls-4" d="M8.17,22.77H27.52v6.36H8.17Z" transform="translate(-5 -1)" /></g></g>
            <g className="cls-7"><g className="cls-8"><path className="cls-4" d="M5,22.77h9.43v8.77H5Z" transform="translate(-5 -1)" /></g></g>
            <g className="cls-9"><g className="cls-10"><path className="cls-4" d="M25.43,18.2V1H32V18.2Z" transform="translate(-5 -1)" /></g></g>
            <g className="cls-11"><g className="cls-12"><g className="cls-13"><path className="cls-4" d="M20.51,27.67l6-15.3,6.14,2.29-6,15.3Z" transform="translate(-5 -1)" /></g></g></g>
            <g className="cls-14"><g className="cls-15"><path className="cls-4" d="M8,6H23.43V21.28H8Z" transform="translate(-5 -1)" /></g></g>
        </svg>
    );
}

export default SolicitarAtendimentoIcon;