import { Cache } from '@stt-componentes/cache';

export const getHeaders = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
}

export const getHeadersFormData = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' };
};

export const getAuthrizationHeader = () => {
    const token = Cache.getAccessToken();
    return { authorization: `bearer ${token}` };
}