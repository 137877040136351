module.exports = {
    aceitarConvite: 'Aceitar convite',
    agenda: 'Agenda',
    agendadores: 'Agendadores',
    agendamento: 'Agendamento',
    alteracaoRede: 'Alteração de rede',
    aoVivo: 'Ao vivo',
    cadastroCategoria: 'Cadastro de categoria',
    cadastroRede: 'Cadastro de rede',
    categoria: 'Categoria',
    categorias: 'Categorias',
    confirmarAceitarEvento: 'Você confirma o aceite no convite deste evento?',
    confirmarAlterarAgendamento: 'Você tem certeza que deseja alterar os dados deste agendamento?',
    confirmarAlterarCategoria: 'Você tem certeza que deseja alterar esta categoria?',
    confirmarAlterarRede: 'Você tem certeza que deseja alterar esta rede?',
    confirmarCancelarEvento: 'Você tem certeza que deseja cancelar este agendamento?',
    confirmarRecusarEvento: 'Você confirma a recusa no convite deste evento?',
    confirmarSalvarAgendamento: 'Você tem certeza que deseja realizar este agendamento?',
    confirmarSalvarCategoria: 'Você tem certeza que deseja cadastrar esta categoria?',
    confirmarSalvarRede: 'Você tem certeza que deseja cadastrar esta rede?',
    convite: 'Convite',
    conviteAceito: 'Convite aceito',
    conviteNegado: 'Convite negado',
    convitePendente: 'Convite pendente',
    convites: 'Convites',
    convitesLivre: 'Convites livres',
    dataHoraInicio: 'Data/hora de início',
    dataHoraTermino: 'Data/hora de término',
    erroAssuntoMinimo5Caracteres: 'O assunto deve ter no mínimo 5 (cinco) caracteres válidos.',
    erroParticipanteDuplicado: 'Este participante já foi adicionado anteriormente.',
    erroParticipanteObrigatorio: 'É obrigatório a inclusão de ao menos um participante no agendamento',
    eventos: 'Eventos',
    eventosPassados: 'Eventos passados',
    filtroFuncionario: 'Filtro de funcionários',
    instrucaoCadastroCategoria: `<span>- A partir dessa tela, é possível pesquisar e cadastrar categorias de redes.</span>
                                                                                                                                <span>- A partir dessa tela, também é possível alterar e inativar categorias de redes previamente cadastradas.</span>`,
    instrucaoCadastroRede: `<span>- A partir dessa tela, é possível pesquisar e cadastrar redes.</span>
                                                                                                                                <span>- A partir dessa tela, também é possível alterar e inativar redes previamente cadastradas.</span>`,
    participanteAdicionado: 'Participante adicionado',
    participantes: 'Participantes',
    participantesPrincipais: 'Participantes principais',
    permiteRegistro: 'Permite registro',
    permiteRegistroAtendimento: 'Permite registro do atendimento?',
    proximosEventos: 'Próximos eventos',
    recusarConvite: 'Recusar convite',
    rede: 'Rede',
    redes: 'Redes',
    sucessoAceitarAgendamento: 'Convite aceito com sucesso',
    sucessoCancelarAgendamento: 'Agendamento cancelado com sucesso',
    sucessoRecusarAgendamento: 'Convite recusado com sucesso',
    sucessoSalvarAgendamento: 'Agendamento salvo com sucesso',
    tipoFuncionamento: 'Tipo de funcionamento'
}